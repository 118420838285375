import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Close,
  ConditionsModalDialog,
  Button,
  ContentModal,
} from './criteria.css';
import VisuallyHidden from '@reach/visually-hidden';
import '@reach/dialog/styles.css';
// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
const ModalCriteria = ({ children, buttonText, onClickHandler }) => {
  const [isOpen, showModal] = useState(false);
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    disableScrolling(isOpen);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  function onKeyDown({ key }) {
    if (key === 'Escape') {
      showModal(false);
    }
  }

  function disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      showModal(false);
    }
  }

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          showModal(true);
          onClickHandler();
        }}
      >
        {buttonText}
      </Button>

      <ConditionsModalDialog isOpen={isOpen}>
        <ContentModal>
          <Close
            onClick={() => {
              showModal(false);
            }}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <img src="/icons/icon-modal-close.png" alt="close-icon" />
          </Close>
          {children}
        </ContentModal>
      </ConditionsModalDialog>
    </>
  );
};
export default ModalCriteria;

ModalCriteria.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  onClickHandler: PropTypes.func,
};
