export const sortLocations = (
  locations,
  paramLat,
  paramLong,
  paramName,
  searchName
) => {
  if (paramLong != null && paramLat != null && locations.length > 0) {
    locations.forEach(loc => {
      loc[paramName] = calculateDistance(
        loc.Latitude,
        loc.Longitude,
        paramLat,
        paramLong
      );
    });

    locations.sort(function(a, b) {
      return a.distance - b.distance;
    });
    if (
      locations.length > 1 &&
      locations[0].distance === locations[1].distance &&
      locations[0].Name !== searchName &&
      locations[0].Name !== null
    ) {
      Array.prototype.swap = function(index_A, index_B) {
        var input = this;
        var temp = input[index_A];
        input[index_A] = input[index_B];
        input[index_B] = temp;
      };
      locations.swap(
        0,
        locations.findIndex(({ Name }) => Name === `${searchName}`)
      );
    }
    return locations;
  } else {
    return locations;
  }
};

const calculateDistance = (locLat, locLong, paramLat, paramLong) => {
  const convertToRadians = num => {
    return (num * Math.PI) / 180;
  };
  let distance =
    Math.sin(convertToRadians(paramLat)) * Math.sin(convertToRadians(locLat)) +
    Math.cos(convertToRadians(paramLat)) *
      Math.cos(convertToRadians(locLat)) *
      Math.cos(convertToRadians(locLong) - convertToRadians(paramLong));
  //Calculate distance in miles, Earth radius in miles = 3959
  distance = Math.acos(distance.toFixed(8)) * 3959;
  return distance;
};

export const onBoundsChanged = (locations, markers, map) => {
  let currentLocations = [];
  let activeLocations = [];
  for (var i = 0; i < markers.length; i++) {
    if (map.getBounds().contains(markers[i].getPosition())) {
      currentLocations.push({
        Latitude: markers[i].lat,
        Longitude: markers[i].long,
      });
      // markers[i] in visible bounds
    } else {
      // markers[i] is not in visible bounds
    }
  }
  locations.map(item => {
    currentLocations.map(activeItem => {
      if (
        item.Latitude.includes(activeItem.Latitude) &&
        item.Longitude.includes(activeItem.Longitude)
      ) {
        activeLocations.push(item);
      }
    });
  });
  return activeLocations;
};
