import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import { pinkFont } from '../../constants/theme';

export const ConditionsModalDialog = styled(Dialog)`
  z-index: 9;
  box-sizing: border-box;
  position: relative;
  width: 100% !important;
  min-height: calc(100vh - 136px);
  max-width: 920px;
  background-color: transparent !important;
  padding: 0 17px 0 0 !important;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 80px !important;
  margin-bottom: 0 !important;
  // @media (min-width: 1200px) {
  //   max-width: 1170px;
  // }
  // @media (min-width: 992px) and (max-width: 1199px) {
  //   max-width: 800px;
  // }
  @media (min-width: 576px) and (max-width: 991px) {
    padding: 0 9px !important;
  }
  @media (max-width: 575px) {
    padding: 8px !important;
    margin-top: 0 !important;
  }
  @media (max-width: 992px) and (orientation: landscape) {
    height: max-content !important;
    margin: 20px 0 !important;
  }
`;
export const ContentModal = styled.div`
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 16px 53px !important;
  position: relative;
  width: 100%;
  ul {
    padding-left: 40px;
    margin-bottom: 16px;
    li {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p {
    padding-left: 40px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  h2 {
    color: ${pinkFont};
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 8px;
  }
  .mainContainer {
    width: auto;
  }
  @media (max-width: 575px) {
    margin-top: 0 !important;
    padding: 16px 46px !important;
  }
`;

export const Button = styled.button`
  color:${pinkFont}
  background: transparent;
  border:none;
  font-size: 16px;
  line-height: 19.2px;
  text-align: left;
  padding: 0;

`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: ${pinkFont};
  border: none;
  font-weight: normal;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  padding: 10px;
  top: 16px;
  right: 16px;
  &:focus {
    outline: none;
  }
  img {
    line-height: 22px;
    width: 22px;
    height: 22px;
  }
  z-index: 5;
`;
