import { navigate } from 'gatsby';

export const useNavigation = baseUrl => {
  return {
    go: () => {
      navigate(baseUrl);
    },

    withParams: params => {
      if (!Object.keys(params).length) {
        navigate(baseUrl);
      } else {
        navigate(
          `${baseUrl}?${Object.keys(params)
            .map(
              k =>
                encodeURIComponent(k) +
                '=' +
                encodeURIComponent(
                  Array.isArray(params[k])
                    ? params[k].join('~')
                    : `${params[k]}`
                )
            )
            .join('&')}`
        );
      }
    },
  };
};
